@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css?family=Bangers|Cinzel:400,700,900|Lato:100,300,400,700,900|Lobster|Lora:400,700|Mansalva|Muli:200,300,400,600,700,800,900|Open+Sans:300,400,600,700,800|Oswald:200,300,400,500,600,700|Roboto:100,300,400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url(https://fonts.googleapis.com/css?family=Anonymous+Pro);


body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}
.hero{
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.logo-5{font-family: 'Anonymous Pro';}

.cta { background: #e9edf6 !important;}
.cta h2{ font-size: 26px;}
.cta p{ font-size: 20px;}

.navbar{
 height: 100px !important;
 padding: 0% !important;
}

.hero h1{font-size: 35px; font-weight: bold;}
.hero span{font-size: 20px; font-weight: bold;}

.intro h3{font-size: 20px;}
#about{
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.images{
  width: 300px !important;
}

/* .boxes{
  height: 330px !important;
} */

.boxes:hover{
  border-bottom: 5px solid #1E3A8A !important;
}


.hero{
  background-image: url(./images/red.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  font-family: 'Anonymous Pro';
}
.contact-heading{
  font-family: 'Anonymous Pro';
}
.contact-box{
  font-family: 'Anonymous Pro';
}

.tab-img img{
  width: 100px !important;
  text-align: center !important;
}

.bg-info{
  background-color: #e9edf6 !important;
  /* background-color: #e9edf6; */
}
.box{
  text-align: center !important;
  justify-content: center;
  height: 130px;
  align-items: center;
  

}

.services{
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:#e9edf6;
}

.img-tab{
  display: flex;
  justify-content: center;
}
.nav-link.active{
  background: #cdd4e4 !important;
  border-bottom: 5px solid #15285D;
}

ul li{
  list-style: square;
  color: #000;
  padding-left: 0px !important;
}

ul li::marker{
  color: #1E3A8A;
}

.build {
  min-height: 85vh;
  align-items: center;
  display: flex;
  background-color:#fff;

}

ul{
  padding-left: 0px !important;
}

.about{
  background-color:#fff !important;
}

.bg-primary{
  background: #15285D !important;
}

footer{
  background-color: #fff;
}
footer ul li{
  color: #000 !important;

}

footer .inner-foot:hover{
  color: black !important;
  padding-bottom: 5px !important;
  border-bottom:2px solid #92a9e0;
  
}
.inner-foot-social:hover{
  color: black !important;
  
}

.side-box{
  top: 0px !important;
  margin-top: -460px !important;
}


.style-text{
  font-family: 'Anonymous Pro';
  color: #15285D !important;
}

.nav-linkk{
  font-size: 18px;
  font-weight: 800 !important;
  font-family: 'Anonymous Pro';
}
.nav-linkkk{
  font-size: 23px;
}

.readBtn{
  color: #1E3A8A;
  text-decoration: none;
  font-size: 12px;
  text-transform: capitalize;
  padding: 0px !important;
}

.text-warning{
  color: #1E3A8A !important;
}

#particles-js {
  background-size: cover;
  width: 100%;
  top: 0px;
  height: 100vh !important;
  z-index: 1;
  background-color: #E9EDF6 ;
  padding: 0 100px;
 
 }
 .inner{
  width: 100% !important;
  z-index: -999;
  background: transparent;
  
 }

 .blog-hover:hover{opacity: .5;}
 .blog-main{min-height: 100vh;}

 .loader{min-height: 50vh;display: flex; align-items: center; justify-content: center;}

 .full-blogs h1{font-weight: 700;}
 .full-blogs p{color: rgb(97, 97, 97);margin-bottom: 40px;}
 .full-blogs h2{font-weight: 700;color: #1E3A8A;}
 .full-blogs ul li{color: rgb(97, 97, 97);}