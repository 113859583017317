/* styles/BlogPage.css */

/* Include Tailwind CSS styles */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Your existing custom styles */
.blog-container {
  max-width: 800px;
  margin: 0 auto;
}

.blog-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.blog-item {
  margin-bottom: 2rem;
}

.blog-content {
  line-height: 1.6;
}
.nav-container {
  padding-top: 1rem; /* Adjust as needed */
}

body {
    font-family: Arial, sans-serif;
    line-height: 1.5;
}

h1 {
    color: rgb(30 58 138);
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
}

h2 {
    color: #444;
    font-size: 20px;
    margin-bottom: 10px;
}

h3 {
  color: #555;
  font-size: 15px;
  margin-bottom: 10px;
}

p {
    color: #333;
    margin-bottom: 10px;
}

ul {
    list-style-type: disc;
    margin-bottom: 10px;
    margin-left: 20px;
}

ol {
    list-style-type: decimal;
    margin-bottom: 10px;
    margin-left: 20px;
}

li {
    color: #333;
    margin-bottom: 5px;
}